<template>
  <div style="position: relative;"  v-if="field.Visable">
    <van-field :required="field.Nullable == true" name="calendar"  :placeholder="'选择日期'"  readonly :center="true" :right-icon="(isEditSaved || !field.Editable) ? '' : 'cross'"
               @click-right-icon="changeValue(tablecode, rowindex, config.FieldCode, '')"
               :disabled="isEditSaved || !field.Editable"
               @click-input="editFieldBefor" v-model="dataSet[tablecode][rowindex][config.FieldCode]">
      <template slot="label">
        <div v-if="config.labelWidth !== '0'" :style="'width: ' + config.labelWidth + 'px; color: ' + config.wordColor +'; font-weight: ' + config.wordWeight + '; font-size: ' + config.wordSize + 'px'">{{ config.name }}</div>
      </template>
    </van-field>
    <van-calendar  v-if="!config.hasTime"
                   :default-date="defaultDate"
                   v-model="showPicker" @confirm="onConfirm" />
    <van-popup  v-if="config.hasTime" v-model="showPicker" position="bottom">
      <van-datetime-picker
              v-model="defaultDate"
              @confirm="onConfirm" @cancel="showPicker = false" />
    </van-popup>
  </div>
</template>

<script>
import { CellGroup, Field, Popup, Calendar, DatetimePicker } from 'vant'
export default {
    name: 'nxMobileDatetime',
    components: {
        [CellGroup.name]: CellGroup,
        [Field.name]: Field,
        [Popup.name]: Popup,
        [Calendar.name]: Calendar,
        [DatetimePicker.name]: DatetimePicker
    },
    props: {
        mastertable: Object,
        tables: Array,
        dataSet: Object,
        config: Object,
        label: String,
        isEditSaved: Boolean,
        rowindex: Number,
        tablecode: String,
        editFieldBefore: Function,
        editFieldBeforeV2: Function,
        changeValue: Function
    },
    data () {
      return {
          field: null,
          defaultDate: new Date(),
          showPicker: false
      }
    },
    mounted () {
        const tableList = this.tables
        tableList.push(this.mastertable)
        for (let i = 0; i < tableList.length; i++) {
            const table = tableList[i]
            if (table.TableCode === this.tablecode) {
                for (let j = 0; j < table.Fields.length; j++) {
                    if (table.Fields[j].FieldCode === this.config.FieldCode) {
                        this.field = table.Fields[j]
                    }
                }
            }
            if (this.field !== null) {
                break
            }
        }
        console.log(this.field)
    },
    methods: {
        onConfirm (checkResult) {
            this.defaultDate = checkResult
            let date = checkResult.getFullYear().toString() + '-' + (checkResult.getMonth() + 1).toString() + '-' + checkResult.getDate().toString();
            if (this.config.hasTime) {
                date =  date +  ' ' + checkResult.getHours().toString() + ':' + checkResult.getMinutes().toString()
            }
            this.changeValue(this.tablecode, this.rowindex, this.config.FieldCode, date)
            this.showPicker = false
        },
        editFieldBefor () {
            this.editFieldBefore(this.tablecode, this.rowindex, this.config.FieldCode)
            this.editFieldBeforeV2()
            if (!this.isEditSaved && this.field.Editable) {
                this.showPicker = true
            }
        },
    }
}
</script>

<style scoped>

</style>
