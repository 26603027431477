<template>
  <div style="position: relative;">
    <div style="margin-left: 15px; margin-right: 15px; color: #333;">{{value}}</div>
    <!-- 删除组件 -->
    <slot name="deles" />
  </div>
</template>

<script>
export default {
  name: 'nxText',
  props: {
    value: String,
    config: Object,
    children: Array,
    field: Object,
    label: String,
    cssdiy: Object
  }
}
</script>

<style scoped>

</style>
