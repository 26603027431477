<template>
  <div style="position: relative;">
    <van-button type="primary" :color="config.backColor" :disabled="isEditSaved" @click="buttonClick"><span :style="'color:' + config.wordColor">{{config.text}}</span></van-button>
    <!-- 删除组件 -->
    <slot name="deles" />
  </div>
</template>

<script>
    import { Field, Button } from 'vant'
    export default {
        name: 'nxMobileButton',
        components: {
            [Field.name]: Field,
            [Button.name]: Button
        },
        props: {
            value: String,
            label: String,
            config: Object,
            children: Array,
            field: Object,
            isEditSaved: Boolean,
            doAction: Function
        },
        methods: {
            buttonClick () {
                const listAction = []
                listAction.push(this.config.actionID)
                this.doAction(listAction)
            }
        }
    }
</script>

<style scoped>

</style>
