import { render, staticRenderFns } from "./nxMobileSwitch.vue?vue&type=template&id=7f4c3f71&scoped=true&"
import script from "./nxMobileSwitch.vue?vue&type=script&lang=js&"
export * from "./nxMobileSwitch.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f4c3f71",
  null
  
)

export default component.exports