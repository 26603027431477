<template>
  <div style="position: relative;">
    <van-field :required="field.Nullable == true" :label="label"   :label-width="config.labelWidth"  :disabled="isEditSaved || !field.Editable">
      <template #input>
        <van-switch size="26px"></van-switch>
      </template>
    </van-field>
    <!-- 删除组件 -->
    <slot name="deles" />
  </div>
</template>

<script>
    import { Field, Switch } from 'vant'
    export default {
        name: 'nxMobileSwitch',
        components: {
            [Field.name]: Field,
            [Switch.name]: Switch
        },
        props: {
            value: String,
            label: String,
            config: Object,
            children: Array,
            mastertable: Object,
            tables: Array,
            cssdiy: Object,
            isEditSaved: Boolean
        },
        mounted () {
            const tableList = this.tables
            tableList.push(this.mastertable)
            for (let i = 0; i < tableList.length; i++) {
                const table = tableList[i]
                if (table.TableCode === this.tablecode) {
                    for (let j = 0; j < table.Fields.length; j++) {
                        if (table.Fields[j].FieldCode === this.config.FieldCode) {
                            this.field = table.Fields[j]
                        }
                    }
                }
                if (this.field !== null) {
                    break
                }
            }
        }
    }
</script>

<style scoped>

</style>
