<template>
  <div style="position: relative;">
    <van-field :required="field.Nullable == true" :disabled="isEditSaved || !field.Editable"
                :placeholder="config.description"  :rows="config.rows" :type="config.type"  v-model="dataSet[tablecode][rowindex][config.FieldCode]" @change="changeV" @focus="editFieldBefor" :label-width="config.labelWidth">
      <template slot="label">
        <div v-if="config.labelWidth !== '0'" :style="'width: ' + config.labelWidth + 'px; color: ' + config.wordColor +'; font-weight: ' + config.wordWeight + '; font-size: ' + config.wordSize + 'px'">{{ config.name }}</div>
      </template>
      <template #button>
        <van-button v-if="field.Linkers != null  && field.Linkers.length > 0"  type="default" style="width: 12px;height: 12px;" :icon="require('@/assets/images/report/linker.png')" @click="linkReport()"></van-button>
      </template>
    </van-field>
  </div>
</template>

<script>
import { Field, Button } from 'vant'
export default {
    name: 'nxMobileInput',
    components: {
        [Field.name]: Field,
        [Button.name]: Button

    },
    props: {
        mastertable: Object,
        tables: Array,
        dataSet: Object,
        config: Object,
        label: String,
        isEditSaved: Boolean,
        rowindex: Number,
        tablecode: String,
        editFieldBefore: Function,
        editFieldBeforeV2: Function,
        editFieldAfter: Function,
        linkReportV2: Function
    },
    data () {
      return {
          field: null
      }
    },
    mounted () {
        const tableList = this.tables
        tableList.push(this.mastertable)
        for (let i = 0; i < tableList.length; i++) {
            const table = tableList[i]
            if (table.TableCode === this.tablecode) {
                for (let j = 0; j < table.Fields.length; j++) {
                    if (table.Fields[j].FieldCode === this.config.FieldCode) {
                        this.field = table.Fields[j]
                    }
                }
            }
            if (this.field !== null) {
                break
            }
        }
    },
    methods:{
        changeV () {
            this.editFieldBefore(this.tablecode, this.rowindex, this.config.FieldCode)
            this.editFieldAfter()
        },
        editFieldBefor () {
            this.editFieldBefore(this.tablecode, this.rowindex, this.config.FieldCode)
            this.editFieldBeforeV2()
        },
        linkReport () {
            this.linkReportV2(this.tablecode, this.config.FieldCode, this.rowindex)
        }
    }
}
</script>

<style scoped>

</style>
