<template>
  <div style="position: relative;">
    <div style="margin-left: 15px; margin-right: 15px; margin-top: 15px;">
      {{config.label}}
      <div  style="display: inline;color: #1989fa;" v-if="!isEditSaved" @click="addFocuseRow(config.TableCode)">
        <van-icon name="add" size="18" />
      </div>
    </div>
    <div style="margin-top: 10px;"></div>
    <div v-for="(row, rowindex) in dataSet[config.TableCode]" :key="rowindex" >
      <div style="display: block; margin-left: 10px; margin-right: 10px; background-color: #ffffff; border-radius: 8px; box-shadow: 0px 0px 5px #ccc" v-if="config.componentJson">
        <component
                :is="item.component"
                v-for="(item, index) in config.componentJson.pageComponents"
                :table="item.table"
                :data-set="dataSet"
                :key="index"
                :config="item.setStyle"
                :id="item.id"
                :style="{
                  float: 'left',
                  width: item.setStyle === undefined ? '100%' : item.setStyle.col === '1/2' ? '50%' :item.setStyle.col === '1/3' ? '33%' : item.setStyle.col === '1/4' ? '25%' : '100%'
                }"
                :is-edit-saved="isEditSaved"
                :resource-home = 'resourceHome'
                :on-click-upload-image="onClickUploadImage"
                :pop-image-info="popImageInfo"
                :on-click-show-image="onClickShowImage"
                :remove-image="removeImage"
                :remove-affix="removeAffix"
                :rowindex = "rowindex"
                :tablecode = "config.TableCode"
                :change-value="changeValue"
                :edit-field-before="editFieldBefore"
                :edit-field-before-v2="editFieldBeforeV2"
                :on-click-upload-affix="onClickUploadAffix"
                :pop-affix-info="popAffixInfo"
                :preview-affix="previewAffix"
                :edit-field-after="editFieldAfter"
                :field="item.setStyle.field"
                :tables="tables"
                :mastertable="mastertable"
                class="componentsClass"
        >
        </component>
        <div style="height: 10px; position: relative; float: left; width: 100%;"></div>
      </div>
    </div>
    <!--<div style="margin-left: 10px; margin-right: 10px; margin-top: 5px; ">-->
      <!--<van-row>-->
        <!--<van-col span="24">-->
          <!--<van-button round plain  type="info"  style="color: #1989fa; height: 30px; width: 100%">-->
            <!--<span style="font-size:.9rem;font-weight:bold">+</span> <span-->
            <!--style="font-size:.7rem;">{{config.label}}</span>-->
          <!--</van-button>-->
        <!--</van-col>-->
      <!--</van-row>-->
    <!--</div>-->
    <!-- 删除组件 -->
    <slot name="deles" />
  </div>
</template>

<script>
import { Icon, Row, Col, Button, Collapse, CollapseItem, SwipeCell } from 'vant'
export default {
  name: 'nxMobileMycomponent',
  components: {
    [Icon.name]: Icon,
    [Row.name]: Row,
    [Col.name]: Col,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [SwipeCell.name]: SwipeCell
  },
  props: {
      mastertable: Object,
      tables: Array,
      value: String,
      config: Object,
      children: Array,
      field: Object,
      cssdiy: Object,
      label: String,
      isEditSaved: Boolean,
      onClickUploadImage: Function,
      popImageInfo: Function,
      onClickShowImage: Function,
      removeImage: Function,
      removeAffix: Function,
      resourceHome: String,
      dataSet: Object,
      changeValue: Function,
      addFocuseRow: Function,
      editFieldBefore: Function,
      editFieldBeforeV2: Function,
      popAffixInfo: Function,
      previewAffix: Function,
      onClickUploadAffix: Function,
      editFieldAfter: Function
  },
  data () {
    return {
      deleShow: true
    }
  },
  mounted () {
      console.log(this.config.TableCode)
      console.log(this.dataSet)
  },
  methods: {
    rowSchem (dataSet, config, rowIndex) {
      let strSchem = ''
      for (let i = 0; i < config.fieldScheam.length; i++) {
        const field = config.fieldScheam[i]
        strSchem += field.FieldTitle + ':值  '
      }
      return strSchem
    }
  }
}
</script>

<style scoped>

</style>
