<template>
  <div style="position: relative;"  v-if="field.Visable">
    <van-field :required="field.Nullable == true" :label="label"   :label-width="config.labelWidth"  :disabled="isEditSaved || !field.Editable">
      <template #input>
        <van-slider></van-slider>
      </template>
    </van-field>
    <!-- 删除组件 -->
    <slot name="deles" />
  </div>
</template>

<script>
    import { Field, Slider } from 'vant'
    export default {
        name: 'nxMobileSlider',
        components: {
            [Field.name]: Field,
            [Slider.name]: Slider
        },
        props: {
            value: String,
            label: String,
            config: Object,
            children: Array,
            mastertable: Object,
            tables: Array,
            cssdiy: Object,
            isEditSaved: Boolean
        },
        data () {
            return {
                field: null
            }
        },
        mounted () {
            const tableList = this.tables
            tableList.push(this.mastertable)
            for (let i = 0; i < tableList.length; i++) {
                const table = tableList[i]
                if (table.TableCode === this.tablecode) {
                    for (let j = 0; j < table.Fields.length; j++) {
                        if (table.Fields[j].FieldCode === this.config.FieldCode) {
                            this.field = table.Fields[j]
                        }
                    }
                }
                if (this.field !== null) {
                    break
                }
            }
        }
    }
</script>

<style scoped>

</style>
