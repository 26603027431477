<template>
  <div style="position: relative;"  v-if="field.Visable">
    <van-field :required="field.Nullable == true" :label="label"   :label-width="config.labelWidth"  :disabled="isEditSaved || !field.Editable">
      <template slot="label">
        <div v-if="config.labelWidth !== '0'" :style="'width: ' + config.labelWidth + 'px; color: ' + config.wordColor +'; font-weight: ' + config.wordWeight + '; font-size: ' + config.wordSize + 'px'">{{ config.name }}</div>
      </template>
      <template #input>
        <van-checkbox shape="square" v-model="dataSet[tablecode][rowindex][config.FieldCode]"   :disabled="isEditSaved || !field.Editable"></van-checkbox>
      </template>
    </van-field>
    <!-- 删除组件 -->
    <slot name="deles" />
  </div>
</template>

<script>
import { Field, Checkbox } from 'vant'
export default {
  name: 'nxMobileRadio',
  components: {
    [Field.name]: Field,
    [Checkbox.name]: Checkbox

  },
  props: {
      dataSet: Object,
      value: String,
      config: Object,
      children: Array,
      mastertable: Object,
      tables: Array,
      cssdiy: Object,
      label: String,
      isEditSaved: Boolean,
      onClickUploadImage: Function,
      popImageInfo: Function,
      onClickShowImage: Function,
      removeImage: Function,
      removeAffix: Function,
      resourceHome: String,
      rowindex: Number,
      tablecode: String,
      editFieldBefore: Function,
      popAffixInfo: Function,
      previewAffix: Function,
      onClickUploadAffix: Function
  },
    data () {
        return {
            field: null
        }
    },
    mounted () {
        const tableList = this.tables
        tableList.push(this.mastertable)
        for (let i = 0; i < tableList.length; i++) {
            const table = tableList[i]
            if (table.TableCode === this.tablecode) {
                for (let j = 0; j < table.Fields.length; j++) {
                    if (table.Fields[j].FieldCode === this.config.FieldCode) {
                        this.field = table.Fields[j]
                    }
                }
            }
            if (this.field !== null) {
                break
            }
        }
    }
}
</script>

<style scoped>

</style>
