<template>
  <div style="position: relative;">
    <div style="margin-left: 15px; margin-right: 15px; margin-top: 15px;">
      {{config.label}}
      <div  style="display: inline;color: #1989fa;"  v-if="!isEditSaved">
        <van-icon name="add" size="18"  @click="addFocuseRow(config.TableCode)"/>
      </div>
    </div>
    <div style="margin-left: 10px; margin-right: 10px; margin-top: 5px; background-color: #ffffff; border-radius: 8px; box-shadow: 0px 0px 5px #ccc">
      <van-collapse v-model="activeNames" v-if="config.fieldJson.length > 0">
        <van-swipe-cell   v-for="(row, ri) in dataSet[config.TableCode]" :key="ri">
          <van-collapse-item :title="config.showSchema ? rowSchem(dataSet, config, ri) : config.label + (ri + 1)" :name="ri">
            <component
              :is="item.component"
              v-for="(item, index) in config.fieldJson"
              :table="item.table"
              :data-set="dataSet"
              :key="index"
              :value="item.value"
              :label="item.label"
              :config="item.setStyle"
              :id="item.id"
              :style="{
                  float: 'left',
                  width: item.setStyle === undefined ? '100%' : item.setStyle.col === '1/2' ? '50%' :item.setStyle.col === '1/3' ? '33%' : item.setStyle.col === '1/4' ? '25%' : '100%'
                }"
              :is-edit-saved="isEditSaved"
              :resource-home = 'resourceHome'
              :on-click-upload-image="onClickUploadImage"
              :pop-image-info="popImageInfo"
              :on-click-show-image="onClickShowImage"
              :remove-image="removeImage"
              :remove-affix="removeAffix"
              :rowindex = "ri"
              :tablecode = "config.TableCode"
              :change-value="changeValue"
              :edit-field-before="editFieldBefore"
              :edit-field-before-v2="editFieldBeforeV2"
              :on-click-upload-affix="onClickUploadAffix"
              :pop-affix-info="popAffixInfo"
              :preview-affix="previewAffix"
              :edit-field-after="editFieldAfter"
              :field="item.setStyle.field"
              :tables="tables"
              :mastertable="mastertable"
              class="componentsClass"
            >
            </component>
          </van-collapse-item>
            <!--<template v-if="template.HideMenuItems.indexOf('RemoveRow')<0 && table.CanAddRows" #right>-->
                <!--<van-button square type="danger" text="删除" @click="deleteFocuseRow(ri, table)"/>-->
            <!--</template>-->
            <template #right>
                <van-button  square type="danger" text="删除" @click="deleteFocuseRow(ri, config.TableCode)"/>
            </template>
        </van-swipe-cell>
      </van-collapse>
    </div>
    <div style="margin-left: 10px; margin-right: 10px; margin-top: 5px; "  v-if="!isEditSaved"  @click="addFocuseRow(config.TableCode)">
      <van-row>
        <van-col span="24">
          <van-button round plain  type="info"  style="color: #1989fa; height: 30px; width: 100%">
            <span style="font-size:.9rem;font-weight:bold">+</span> <span
            style="font-size:.7rem;">{{config.label}}</span>
          </van-button>
        </van-col>
      </van-row>
    </div>
    <div style="height: 10px;"></div>
  </div>
</template>

<script>
import { Icon, Row, Col, Button, Collapse, CollapseItem, SwipeCell } from 'vant'
export default {
  name: 'nxMobileGroup',
  components: {
    [Icon.name]: Icon,
    [Row.name]: Row,
    [Col.name]: Col,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [SwipeCell.name]: SwipeCell
  },
  props: {
      mastertable: Object,
      tables: Array,
      value: String,
      config: Object,
      children: Array,
      field: Object,
      cssdiy: Object,
      label: String,
      isEditSaved: Boolean,
      onClickUploadImage: Function,
      popImageInfo: Function,
      onClickShowImage: Function,
      removeImage: Function,
      removeAffix: Function,
      resourceHome: String,
      dataSet: Object,
      changeValue: Function,
      addFocuseRow: Function,
      editFieldBefore: Function,
      editFieldBeforeV2: Function,
      popAffixInfo: Function,
      previewAffix: Function,
      onClickUploadAffix: Function,
      editFieldAfter: Function,
      deleteFocuseRow: Function
  },
  data () {
    return {
      activeNames: [],
      deleShow: true
    }
  },
  mounted () {
      console.log(this.config)
      console.log(this.dataSet)
  },
  methods: {
    rowSchem (dataSet, config, rowIndex) {
      let strSchem = ''
      for (let i = 0; i < config.fieldScheam.length; i++) {
        const field = config.fieldScheam[i]
        strSchem += field.FieldTitle + ':' + (dataSet[config.TableCode][rowIndex][field.FieldName] === undefined ? '' : dataSet[config.TableCode][rowIndex][field.FieldName]) + '  '
      }
      return strSchem
    }
  }
}
</script>

<style scoped>

</style>
